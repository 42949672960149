import { BrowserModule, Title } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { CommonModule, registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { ToastrModule } from 'ngx-toastr';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { HomeComponent } from './modules/home/pages/home.component';
import { UiModule } from './modules/ui.module';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { Reducer } from '@reducers/reducer';
import { ExternalPageModule } from './modules/external-page/external-page.module';
import { TabsetModule } from '@components/tabset/tabset.module';
import { TabReducer } from '@reducers/tab.reducer';
import { ErrorModule } from './modules/error/error.module';
import {AvisosModule} from './modules/administracao/avisos/avisos.module';
import {AvisoDetalheModule} from './modules/administracao/avisos/aviso-detalhe/aviso-detalhe.module';
import {UsuarioLogadoModule} from './modules/usuario-logado/usuario-logado.module';
import {UsuariosModule} from './modules/administracao/usuarios/usuarios.module';

import {UsuariosLogadosModule} from './modules/monitoramento/usuarios-logados/usuarios-logados.module';
import {UsuarioDetalheModule} from './modules/administracao/usuarios/usuario-detalhe/usuario-detalhe.module';
import {VisibilidadesModule} from './modules/administracao/visibilidades/visibilidades.module';
import {VisibilidadeDetalheModule} from './modules/administracao/visibilidades/visibilidade-detalhe/visibilidade-detalhe.module';
import {PlanosModule} from './modules/administracao/planos/planos.module';
import {PlanoDetalheModule} from './modules/administracao/planos/plano-detalhe/plano-detalhe.module';
import {ModulosModule} from './modules/administracao/modulos/modulos.module';
import {ModuloDetalheModule} from './modules/administracao/modulos/modulo-detalhe/modulo-detalhe.module';
import {DataService} from '@services/data.service';
import {PerfisModule} from './modules/administracao/perfis/perfis.module';
import {PerfilDetalheModule} from './modules/administracao/perfis/perfil-detalhe/perfil-detalhe.module';
import {ContasModule} from './modules/contas/contas.module';
import {ContaDetalheModule} from './modules/contas/conta-detalhe/conta-detalhe.module';
import {CreditosCaptchaModule} from './modules/administracao/captcha/creditos-captcha.module';
import {RelatoriosModule} from './modules/monitoramento/relatorios/relatorios.module';
import {ContasLimitesModule} from './modules/relatorios/contas-limites/contas-limites.module';
import {StatusBaixasModule} from './modules/monitoramento/statusBaixas/statusBaixas.module';
import {JobManagerModule} from './modules/monitoramento/jobManager/jobManager.module';
import {BaixasPorContaModule} from './modules/monitoramento/baixasPorConta/baixasPorConta.module';
import {LoadingModule} from './modules/loading/loading.module';
import {CreditosModule} from './modules/administracao/creditos/creditos.module';
import {DispositivosAgentModule} from "./modules/monitoramento/dispositivos-agent/dispositivos-agent.module";

const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);
const maskConfig: Partial<IConfig> = {
    validation: false,
};
registerLocaleData(pt);

const reducers: ActionReducerMap<any> = {
    tabs: TabReducer,
    forms: Reducer,
};

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
    ],
    imports: [
        LoadingModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ContasLimitesModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CommonModule,
        NzLayoutModule,
        UiModule,
        NzBadgeModule,
        NzTabsModule,
        NgxMaskModule.forRoot(maskConfig),
        ExternalPageModule,
        TabsetModule,
        UsuarioLogadoModule,
        UsuariosModule,
        AvisosModule,
        AvisoDetalheModule,
        UsuariosLogadosModule,
        UsuarioDetalheModule,
        CreditosModule,
        VisibilidadesModule,
        VisibilidadeDetalheModule,
        PlanosModule,
        PlanoDetalheModule,
        RelatoriosModule,
        ModulosModule,
        ModuloDetalheModule,
        ContasModule,
        ContaDetalheModule,
        PerfisModule,
        PerfilDetalheModule,
        ErrorModule,
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            }
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        CreditosCaptchaModule,
        StatusBaixasModule,
        JobManagerModule,
        BaixasPorContaModule,
        DispositivosAgentModule
    ],
    providers: [
        { provide: NZ_I18N, useValue: pt_BR },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: NZ_ICONS, useValue: icons },
        { provide: LOCALE_ID, useValue: 'pt' },
        DataService,
        Title
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
