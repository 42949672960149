import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {formatDate} from '@angular/common';
import {Conta} from '@models/conta.model';
import {environment} from '../../../../environments/environment';
import {Helpers} from "../../../core/helpers";

@Injectable({providedIn: 'root'})

export class ContaDetalheService extends AbstractService<Conta> {

    constructor(http: HttpClient) {
        super(http, '/conta', {});
    }

    get(id: number | string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${id}`);

    }

    getImagens(id: number | string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${id}/imagens`);

    }

    uploadImagem(id: string, formData: FormData): Observable<any> {

        return this.http.post(`${this.baseUrl}/detalhe/${id}/imagens`, formData);

    }

    removerLogo(id: string, tipo: string): Observable<any> {

        return this.http.delete(`${this.baseUrl}/detalhe/${id}/imagens/${tipo}`);
    }

    getInfraestrutura(id: number | string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${id}/infraestrutura`);

    }

    getElasticSearch(id: number | string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/elasticsearch/hosts`);

    }

    getServidores(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/servidores/select`);

    }

    getConfgsGerais(id: number | string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${id}/geral`);

    }

    getPlanos(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/planos/select`);

    }

    getVisibilidades(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/visibilidades/select`);

    }

    checkInfra(contaId: string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${contaId}/infraestrutura/check`);

    }

    gerarDatabase(contaId: string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${contaId}/infraestrutura/gerarDatabase`);

    }

    gerarS3(contaId: string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${contaId}/infraestrutura/gerarS3`);

    }

    gerarEmbedded(contaId: string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${contaId}/infraestrutura/gerarEmbedded`);

    }

    gerarDominio(contaId: string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${contaId}/infraestrutura/gerarDominio`);

    }


    alterarInfra(contaId: string, id: string, dados: any): Observable<any> {

        return this.http.put(`${this.baseUrl}/detalhe/${contaId}/infraestrutura/${id}`, dados);

    }


    vincularPlano(contaId: string, dados: string = null): Observable<any> {

        return this.http.put(`${this.baseUrl}/detalhe/${contaId}/geral/vincularPlano`, dados);
    }

    alterarModulo(contaId: string, dados: any): Observable<any> {


        const id = dados.id ? dados.id : null;

        if (dados.competencia) {
            dados.competencia = Helpers.formatDateDb(dados.competencia).substring(0, 7);
        }

        return this.http.put(`${this.baseUrl}/detalhe/${contaId}/modulos/detalhe/${id}`, dados);
    }

    alterarCredito(contaId: string, dados: any): Observable<any> {


        const id = dados.id ? dados.id : null;

        return this.http.put(`${this.baseUrl}/detalhe/${contaId}/creditos/detalhe/${id}`, dados);
    }

    removerModulo(contaId: string, moduloId: any): Observable<any> {


        return this.http.delete(`${this.baseUrl}/detalhe/${contaId}/modulos/detalhe/${moduloId}`);
    }

    removerCredito(contaId: string, creditoId: any): Observable<any> {


        return this.http.delete(`${this.baseUrl}/detalhe/${contaId}/creditos/detalhe/${creditoId}`);
    }

    solicitarInfra(contaId: string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${contaId}/infraestrutura/solicitarInfra`);
    }

    reportarInfra(contaId: string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${contaId}/infraestrutura/reportarInfra`);
    }


    adicionarModulo(contaId: string, dados: any): Observable<any> {

        if (dados.competencia) {
            dados.competencia = Helpers.formatDateDb(dados.competencia).substring(0, 7);
        }

        return this.http.post(`${this.baseUrl}/detalhe/${contaId}/modulos`, dados);
    }

    getModulo(contaId: string, moduloId: string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${contaId}/modulos/detalhe/${moduloId}`);

    }

    getCredito(contaId: string, creditoID: string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${contaId}/creditos/detalhe/${creditoID}`);

    }

    getAllModules(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/modulos/select`);

    }


    save(id, campos): Observable<any> {

        if (campos.primeiroNome) {

            campos.nome = campos.primeiroNome + (campos.sobreNome ? ' ' + campos.sobreNome : '');
            delete campos.primeiroNome;
            delete campos.sobreNome;

        }

        if (campos.nascimentoData) {

            campos.nascimentoData = formatDate(campos.nascimentoData, 'YYYY-MM-dd', 'pt_BR');

        }

        return this.http.put(`${this.baseUrl}/detalhe/${id}`, campos);

    }

    getCreditos(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/creditos/select`);

    }

    adicionarCredito(contaId: string, dados: any): Observable<any> {


        return this.http.post(`${this.baseUrl}/detalhe/${contaId}/creditos`, dados);
    }
}
