import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {Form, UntypedFormBuilder} from '@angular/forms';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {DispositivosAgentService} from './dispositivos-agent.service';


import {Data} from '@angular/router';
import {NzTableQueryParams} from "ng-zorro-antd/table";
import {Pagination} from "@models/pagination.model";
import {environment} from "../../../../environments/environment";


@Component({
    selector: 'app-dispositivos-agent',
    templateUrl: './dispositivos-agent.component.html',
    styleUrls: ['./dispositivos-agent.component.scss']
})
export class DispositivosAgentComponent extends AbstractListTable<any> implements OnInit {
    loadings: { table: boolean } = {table: false};
    registros: {
        id: string,
        identificador: string,
        conta_nome: string,
        consumoDesde: string,
        ultimoLogin: string,
        ultimaTransmissao: string,
        consumo: string,
    }[] = [];
    visibles: { drawer: boolean } = {drawer: false};


    constructor(
        private fb: UntypedFormBuilder,
        private service: DispositivosAgentService,
        private toastService: ToastrService
    ) {
        super(service, null, DispositivosAgentService);


    }

    ngOnInit() {


    }


    getRegistros(params: NzTableQueryParams, search: string = '') {

        const url = environment.apiUrl + '/monitoramento/dispositivos-agent/tabela';
        this.loadings.table = true;

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.registros = [];
            this.registros = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loadings.table = false;
        }, (res) => {
            this.toastService.error('Problemas ao obter dados do servidor.');
            this.loadings.table = false;
        });

    }

    onModalDetalhe(visible: boolean) {
        this.visibles.drawer = visible;
    }
}
