<nz-page-header class="site-page-header" nzTitle="Monitoramento"
                nzSubtitle="Monitoramento de Dispositivos Agent">
</nz-page-header>
<nz-content>
    <nz-card [nzExtra]="cardExtra" nzTitle="Dispositivos Agent"
             [nzLoading]="loadings.table" [nzBodyStyle]="{'padding': loadings.table ? '15px' : '0px'}"
             [ngClass]="expandedCard ? 'card-fullscreen' : ''">
        <ng-template #cardExtra>
            <div class="nav-item d-flex">
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!expandedCard ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>

        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="registros"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  [nzShowTotal]="rangeTemplate"
                  (nzQueryParams)="getRegistros($event)"
                  class="fonte-doze table-small" style="font-size: 12px;">
            <thead>
            <tr>
                <th style="width: 50px;" [nzShowSort]="false"></th>
                <th nzLeft [nzSortFn]="true" nzColumnKey="id">Conta</th>
                <th nzAlign="center" [nzSortFn]="true" nzColumnKey="consumoDesde">Consumo desde</th>
                <th nzAlign="center" [nzSortFn]="true" nzColumnKey="consumoDesde">Último Login</th>
                <th nzAlign="center" [nzSortFn]="true" nzColumnKey="consumoDesde">Última Transmissão</th>
                <th nzAlign="center" [nzSortFn]="true" nzColumnKey="consumoDesde">Consumo</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let registro of basicTable.data">
                <td></td>
                <td>
                    <a nz-button nzType="link" style="text-align: left; line-height: 20px;"
                    (click)="onModalDetalhe(true)">
                        <span style="font-weight: 600; font-size: 12px;">{{ registro.identificador }}</span><br>
                        {{ registro.conta_nome }}
                    </a>
                </td>
                <td>{{ registro.consumoDesde ? (registro.consumoDesde | date: 'dd/MM/YYYY HH:mm') : '-' }}</td>
                <td>{{ registro.ultimoLogin ? (registro.ultimoLogin | date: 'dd/MM/YYYY HH:mm') : '-' }}</td>
                <td>{{ registro.ultimaTransmissao ? (registro.ultimaTransmissao | date: 'dd/MM/YYYY HH:mm') : '-' }}</td>
                <td>{{ registro.consumo }}</td>
            </tr>
            </tbody>
            <ng-template #rangeTemplate let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-table>


    </nz-card>
</nz-content>


<nz-drawer
        [nzClosable]="true"
        [nzVisible]="visibles.drawer"
        nzPlacement="bottom"
        nzTitle="Basic Drawer"
        [nzHeight]="'700px'"
        (nzOnClose)="onModalDetalhe(false)"
>
    <ng-container *nzDrawerContent>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
    </ng-container>
</nz-drawer>

